import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 8-8-8\\@70% 1RM`}</p>
    <p>{`Single Leg Bent Leg Bridge 8-8-8/leg`}</p>
    <p>{`then,`}</p>
    <p>{`90-Walking Lunge Steps`}</p>
    <p>{`70-Double Unders`}</p>
    <p>{`50-KBS’s (53/35)`}</p>
    <p>{`30-Burpees`}</p>
    <p>{`10-Back Squats (225/155)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will have our 2nd annual CrossFit Games party at the Barnett
house on Sunday, August 6th for all Ville & East members.  More info to
come soon!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      